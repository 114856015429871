import * as React from "react";
import styled from "styled-components";
// @ts-ignore
import Pic from "../../images/IMG_4989.webp";

const Background = styled.div`
  background: #0f0f1b;
  transform: skew(0, -16deg);
  padding: 80px 0;
  height: 640px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -80px;
    right: 0;
    left: 0;
    background: #0f0f1b;
    height: 3px;
  }

  @media (max-width: 768px) {
    height: 900px;
  }
`;

const Inner = styled.div`
  transform: skew(0, 16deg) translateY(-60px);
`;

const Wrapper = styled.div`
  max-width: 900px;
  margin: auto;
  padding: 12px;

  p {
    font-style: italic;
  }
`;

const Image = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-size: 150%;
  background-position: 70%;
`;

export default function About() {
  return (
    <Background>
      <Inner>
        <Wrapper>
          <h1 style={{ fontSize: 48 }}>Varför vi finns</h1>
          <p>
            För oss som gillar att koda finns det fantastiska möjligheter. Vår kompetens efterfrågas, vi kan kan arbeta
            med varierande projekt, vi kan försörja oss med något som är intellektuellt och kreativt stimulerande.
            <br />
            <br />
            Men tyvärr finns det en trend av helt onödiga brister bland de större konsultföretagen, något som jag märkte
            på egen hand: man tar för höga procentandelar av vad konsulterna tjänar in, man slösar tid på meningslösa
            möten, man ger inte konsulten rätt uppgifter utifrån dess förutsättningar, man ställer irrelevanta krav på
            utbildningar samtidigt som man struntar i att ge medarbetarna en chans till att utvecklas på riktigt.
            <br />
            <br />
            <b>
              Mixa det där med en AW-centrerad företagskultur och strössla på lite företagsklychor så får du en
              frustration som leder till handling.
            </b>
            <br />
            <br />
            Jag sa upp mig som utvecklare på en konsultfirma.
            <br />
            Men jag gjorde det med vetskapen att något betydligt bättre var möjligt."
          </p>
          <br />
          <div style={{ textAlign: "center", width: "max-content", fontSize: 14, fontWeight: "bold" }}>
            <Image
              style={{
                backgroundImage: `url(${Pic})`,
              }}
            />
            <br />
            Sara Boström
            <br /> Grundare
          </div>
        </Wrapper>
      </Inner>
    </Background>
  );
}
