import * as React from "react";
import styled from "styled-components";

const Background = styled.div`
  background: #0f0f1b;
`;

const Translate = styled.div`
  transform: translateY(60px);
  margin-bottom: calc(60px + 100px);
  text-align: center;
  max-width: 900px;
  margin: auto;
  padding: 40px 12px;

  @media (max-width: 768px) {
    text-align: left;
    transform: translateY(0);
  }
`;

const Heading = styled.h1`
  font-size: 48px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  text-align: left;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
  }
`;

const Box = styled.div`
  width: 100%;
  max-width: 400px;
  background: #050511;
  padding: 48px;

  ul {
    padding: 0 14px;
  }

  h2 {
    padding-bottom: 20px;
  }
`;

const List = styled.ul`
  li {
    padding: 12px 0;
  }
`;

export default function Why() {
  return (
    <Background>
      <Translate>
        <Heading>Fördelar med att jobba här</Heading>
        <br />
        <p>
          Vi vill ha de bästa, därför kommer vi erbjuda det bästa.
          <br />
          Och därför ska det finnas konkreta och betydelsefulla fördelar med att jobba hos oss.
        </p>
        <br />
        <br />

        <Wrapper>
          <Box>
            <h2>Du får friheten att:</h2>
            <List>
              <li>Välja mellan rörlig eller fast lön.</li>
              <li>Välja uppdrag som passar dig.</li>
              <li>Välja om du vill jobba på distans, på kontor eller något däremellan.</li>
            </List>
          </Box>
          <Box>
            <h2>Vi ser till att:</h2>
            <List style={{}}>
              <li>Ge dig utdelning på vår gemensamma vinst.</li>
              <li>Ge dig möjlighet att lära känna likasinnade.</li>
              <li>Ge dig ny kunskap och nya färdigheter.</li>
            </List>
          </Box>
        </Wrapper>
      </Translate>
    </Background>
  );
}
