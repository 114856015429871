import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  max-width: 900px;
  margin: auto;
  text-align: center;
  padding: 12px;

  @media (max-width: 768px) {
    text-align: left;
  }
`;

const Grid = styled.div`
  display: grid;
  margin: 0 auto;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
  position: relative;
  z-index: 3;
`;

const Heading = styled.h1`
  font-size: 48px;
  padding: 28px 0;
`;

const Box = styled.div`
  padding: 34px;
  background: #0f0f1b;
  white-space: pre-line;
  border: 4px solid #050511;

  @media (max-width: 768px) {
    padding: 34px 20px;
  }
`;

export default function Benefits() {
  return (
    <Wrapper>
      <Heading>Vårt löfte till dig</Heading>
      <p style={{ maxWidth: 600, margin: "auto" }}>
        Konsulter är det viktigaste vi har (vi är ett konsultbolag…)
        <br />
        <br />
        Därför ska du med stolthet kunna kalla Nvode för “mitt företag”, och lita på oss som du litar på Stackoverflow
        klockan 03:00 på en måndag.
        <br />
        <br />
        Vad vi med säkerhet tänker lova dig:
      </p>
      <br />
      <Grid>
        <Box>Ingen press till att delta på “roliga aktiviteter” utanför arbetstid.</Box>
        <Box>En rättvis attityd där det du har förtjänat inte tas ifrån dig.</Box>
        <Box>Tydliga uppgifter och kriterier för att lyckas.</Box>
        <Box>Inga onödiga möten.</Box>
      </Grid>
    </Wrapper>
  );
}
