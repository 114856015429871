import * as React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Cta from "../components/sections/Cta";
// @ts-ignore
import LogoIcon2 from "../images/white_logo.webp";
import About from "../components/sections/About";
import Intro from "../components/sections/Intro";
import Benefits from "../components/sections/Benefits";
import Why from "../components/sections/Why";

const Logo = styled.img`
  margin: auto;
  margin-top: 48px;
`;

const Copy = styled.div`
  text-align: center;
  color: #959baa;
  font-size: 12px;
  padding: 20px;
`;

type Props = {
  desktop: number;
  mobile: number;
};

const Spacer = styled.div`
  height: ${(props: Props) => props.desktop}px;

  @media (max-width: 768px) {
    height: ${(props: Props) => props.mobile}px;
  }
`;

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nvode - Zero Nonsense Consulting</title>
        <link rel="canonical" href="https://www.nvode.se" />
        <meta
          name="description"
          content="Nvode är ett modernt techbolag. Vi drivs av passion, frihet och personlig utveckling. Dessa värden präglar hela vår verksamhet och synen på dig som som en del av laget."
        />
      </Helmet>

      <Logo src={LogoIcon2} style={{ width: 132, height: 29, marginTop: 40 }} alt="Nvode" />

      <Spacer desktop={72} mobile={72} />

      <Intro />

      <Spacer desktop={200} mobile={120} />

      <About />

      <Spacer desktop={200} mobile={100} />

      <Benefits />

      <Spacer desktop={120} mobile={80} />

      <Why />

      <Spacer desktop={180} mobile={120} />

      <Cta />

      <Logo src={LogoIcon2} style={{ width: 132, height: 29, marginTop: 40 }} alt="Nvode" />

      <Copy>
        <br />
        <br />
        <br />
        © Nvode AB
        <br />
        <br />
        c/o Helio
        <br />
        Sundbybergs torg 1
        <br />
        172 67 Sundbyberg
        <br />
        <br />
      </Copy>
    </>
  );
};

export default IndexPage;
