import * as React from "react";
import styled from "styled-components";
// @ts-ignore
import Cat from "../../images/compcat.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  max-width: 900px;
  margin: auto;
`;

const Preheader = styled.div`
  font-family: "Neue Machina";
  font-size: 36px;
  padding: 60px 0 120px;
  text-align: center;
  max-width: 800px;

  @media (max-width: 768px) {
    font-size: 28px;
    text-align: left;
    padding: 50px 0;
  }
`;

const Header = styled.h1`
  text-transform: uppercase;
  background: linear-gradient(70deg, #6eb7ce, #4824be, #fa9983);
  font-size: 82px;
  text-align: center;
  z-index: 2;
  line-height: 1;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 48px;
    text-align: left;
  }
`;

const Shadow = styled.h1`
  font-size: 82px;
  line-height: 1;
  text-transform: uppercase;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.8);
  color: #050511;
  transform: translateY(-46px);
  margin-bottom: -96px;
  z-index: 1;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 48px;
    text-align: left;
    transform: translateY(-30px);
    margin-bottom: -66px;
  }
`;

const Content = styled.div`
  display: fleX;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CatImage = styled.img`
  max-width: 360px;
`;

const Text = styled.p``;

export default function Intro() {
  return (
    <Wrapper>
      <Header>Utvecklare?</Header>
      <Shadow>Utvecklare?</Shadow>

      <Preheader>Bra! Var med och sätt en ny standard för vad det innebär att vara konsultbolag.</Preheader>

      <Content>
        <CatImage src={Cat} />

        <Text>
          Nvode är ett modernt techbolag. <b>Vi drivs av passion, frihet och personlig utveckling.</b> Dessa värden
          präglar hela vår verksamhet och synen på dig som som en del av laget.
          <br />
          <br />
          Är du duktig så är du duktig - det är inte svårare än så. Därför kommer vi alltid att sätta kompetens och
          ambition framför snygga diplom och långa CV:n.
          <br />
          <br />
          <b>Vi är inte här för att visa hur sköna vi är på LinkedIn.</b> Vi är här för att göra ett jobb, och vi tänker
          göra det bra.
          <br />
          <br />
          Vi sätter en ny standard för vad det innebär att vara konsultbolag.
        </Text>
      </Content>
    </Wrapper>
  );
}
