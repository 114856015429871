import * as React from "react";
import styled from "styled-components";
// @ts-ignore
import Gradient from "../../images/gradient.webp";

const Text = styled.h1`
  font-size: 48px;
`;

const Box = styled.div`
  min-height: 350px;
  background: url(${Gradient});
  background-size: cover;
  width: 100%;
  max-width: 1240px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 36px 12px;
  color: black;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 28px solid transparent;
    border-right: 28px solid transparent;
    border-top: 28px solid #050511;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Inner = styled.div`
  max-width: 700px;
  text-align: center;
`;

export default function Cta() {
  return (
    <Box>
      <Inner>
        <Text>Free Fika</Text>
        <p>
          Här tänkte vi verkligen understryka hur Nvode är en solklar möjlighet för dig som utvecklare. Men vi har en
          känsla av att du förmodligen anar den saken själv om du har läst ända hit.
          <br />
          <br />
          <b>Kontakta Sara:</b>
          <br />
          sara@nvode.se - 070 68 64 773
          <br />
          <br />
          Eller klicka här:
        </p>
        <br />
        <a
          className="button"
          style={{ border: 0, background: "#050511", textDecoration: "none" }}
          href="mailto:sara@nvode.se?subject=Hej!&body=Jag%20vill%20g%C3%A4rna%20g%C3%A4rna%20ta%20en%20fika%20med%20er%20och%20h%C3%B6ra%20mer%20om%20Nvode!"
        >
          Gratis fika
        </a>
      </Inner>
    </Box>
  );
}
